import React from 'react';
import '../styles/main.css';
import headshotPOWE from "../assets/headshotPOWE.jpg";
import Eweek from "../assets/Eweek.JPG";
import techfair from "../assets/techfair.JPG";
import blues from "../assets/blues.jpg";
import graduation from "../assets/graduation.jpg";
import { Link } from 'react-router-dom';

const sections = [
  {
    text: "Need a professional headshot? Look no further.",
    image: headshotPOWE,
    buttonText: "Book Headshots",
    link: "/book-now",
    animation: 'slide-left'
  },
  {
    text: "Remember E-Week? We do!",
    image: Eweek,
    buttonText: "Check it out",
    link: "/gallery",
    animation: 'slide-right'
  },
  {
    text: "TechFair and Networking Events - Another One of Our Areas of Expertise",
    image: techfair,
    buttonText: "Capture my event",
    link: "/book-now",
    animation: 'slide-left'
  },
  {
    text: "Blues, Blues, Blues! We're there every week. Come say hi!",
    image: blues,
    buttonText: "Blues Gallery",
    link: "/gallery",
    animation: 'slide-right'
  },
  {
    text: "Graduations, formals, and so much more!",
    image: graduation,
    buttonText: "Book Now",
    link: "/book-now",
    animation: 'slide-left'
  }
];

function ServicesSections() {
  return (
    <div className="services-sections">
      {sections.map((section, index) => (
        <div className="service-section" key={index} data-aos={section.animation} data-aos-duration="500">
          <img src={section.image} alt={section.text} className="service-image" />
          <div className="service-text">
            <p>{section.text}</p>
            <Link to={section.link}>
              <button className="service-button">{section.buttonText}</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServicesSections;
