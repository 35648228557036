import React from 'react';
import '../styles/footer.css';
import { FaInstagram, FaFacebook, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-links">
        <a href="https://www.instagram.com/eus_ppu/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
          <span>Instagram</span>
        </a>
        <a href="https://www.facebook.com/people/Plumbers-Photographer-Union/61550801457022/" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
          <span>Facebook</span>
        </a>
        <a href="mailto:ppu.president@mcgilleus.ca">
          <FaEnvelope />
          <span>Email Us</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
