import React, { useState, useEffect } from 'react';
import '../styles/WeatherPopup.css';


const WeatherPopup = ({ onClose }) => {
  const [weatherData, setWeatherData] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiKey = process.env.REACT_APP_WEATHER_API_KEY; // Get API key from env

  useEffect(() => {
    const fetchWeatherData = async () => {
      const response = await fetch(`https://api.openweathermap.org/data/2.5/forecast?q=Montreal&appid=${apiKey}`);
      const data = await response.json();

      if (data && data.list) {
        const dailyData = [];
        const dayMap = {};

        data.list.forEach(item => {
          const date = new Date(item.dt * 1000).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
          if (!dayMap[date]) {
            dayMap[date] = [];
          }
          dayMap[date].push(item);
        });

        for (let date in dayMap) {
          const dayItems = dayMap[date];
          const tempMin = Math.min(...dayItems.map(item => item.main.temp_min)) - 273.15;
          const tempMax = Math.max(...dayItems.map(item => item.main.temp_max)) - 273.15;
          const description = dayItems[0].weather[0].description;
          const icon = dayItems[0].weather[0].icon;

          dailyData.push({
            date,
            tempMin: tempMin.toFixed(1),
            tempMax: tempMax.toFixed(1),
            description,
            icon
          });
        }

        setWeatherData(dailyData);
      }

      setLoading(false);
    };

    fetchWeatherData();
  }, [apiKey]);

  return (
    <div className="weather-popup">
      <div className="weather-popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>6-Day Weather Forecast for Montreal</h2>
        <p>If you have control over the day, choose a day with good weather, and ideally some light!</p>
        {loading ? (
          <p>Loading weather data...</p>
        ) : (
          <ul className="weather-list">
            {weatherData.map((day, index) => (
              <li key={index} className="weather-item">
                <img 
                  src={`http://openweathermap.org/img/wn/${day.icon}@2x.png`} 
                  alt={day.description} 
                  className="weather-icon" 
                />
                <p className="weather-date">{day.date}</p>
                <p>{day.description}</p>
                <p>High: {day.tempMax}°C, Low: {day.tempMin}°C</p>
              </li>
            ))}
          </ul>
        )}
        <p> Be sure to book at least 7 BUSINESS days before your shoot date! Check 14-day forecast <a href="https://www.theweathernetwork.com/ca/14-day-weather-trend/quebec/montreal" target="_blank" rel="noopener noreferrer">here</a>.</p>
      </div>
    </div>
  );
};

export default WeatherPopup;
