import React, { useState } from 'react';
import '../styles/Gallery.css';
import blues1 from '../assets/blues1.jpg';
import blues2 from '../assets/blues2.jpg';
import blues3 from '../assets/blues3.jpg';
import blues4 from '../assets/blues4.jpg';
import blues5 from '../assets/blues5.jpg';
import blues6 from '../assets/blues6.jpg';
import blues7 from '../assets/blues7.jpg';
import blues8 from '../assets/blues8.jpg';
import blues9 from '../assets/blues9.jpg';
import blues10 from '../assets/blues10.jpg';

const bluesImages = [
  { image: blues1, correctAnswer: "Green's Pub" },
  { image: blues2, correctAnswer: 'Bieber Blues' },
  { image: blues3, correctAnswer: 'Blues Cheese' },
  { image: blues4, correctAnswer: 'Y-Blues-K' },
  { image: blues5, correctAnswer: 'Halloblues' },
  { image: blues6, correctAnswer: 'Berghain Blues' },
  { image: blues7, correctAnswer: 'Legally Blues' },
  { image: blues8, correctAnswer: 'Eras Tour Blues' },
  { image: blues9, correctAnswer: 'Super Mario Blues' },
  { image: blues10, correctAnswer: 'AbracadaBlues' },
];

const options = [
  ["Green's Pub", "Rock 'n' Roll Blues", 'Country Blues', 'Jazz Night'],
  ['Disco Blues', 'Pop Blues', 'Bieber Blues', '2014 Blues'],
  ['Funky Blues', 'Cheesy Blues', 'Wine and Blues', 'Blues Cheese'],
  ['Millennium Blues', 'Bluesically', 'Y-Blues-K', '2016 Blues'],
  ['Avengers Blues', 'Halloblues', 'Spooky Blues', 'Super Blues'],
  ['Berghain Blues', 'Batman Blues', 'Techno Blues', 'Top Secret Blues'],
  ['Pink Blues', 'Justice Blues', 'Barbie Blues', 'Legally Blues'],
  ['Grammy Blues', 'Eras Tour Blues', 'Concert Blues', 'Celebrity Blues'],
  ['Nintendo Blues', 'Adventure Blues', 'Gaming Blues', 'Super Mario Blues'],
  ['Illusion Blues', 'Wizard Blues', 'AbracadaBlues', 'Magic Blues'],
];

const Gallery = () => {
    const [currentLevel, setCurrentLevel] = useState(0);
    const [score, setScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [perfectScore, setPerfectScore] = useState(false);
  
    const handleAnswer = (answer) => {
      if (answer === bluesImages[currentLevel].correctAnswer) {
        setScore(score + 1);
      }
  
      if (currentLevel < bluesImages.length - 1) {
        setCurrentLevel(currentLevel + 1);
      } else {
        if (score + 1 === 10) {
          setPerfectScore(true);
        }
        setGameOver(true);
      }
    };
  
    const retryGame = () => {
      setCurrentLevel(0);
      setScore(0);
      setGameOver(false);
      setPerfectScore(false);
    };
  
    return (
      <div className="gallery-game">
        <h1>Think you know Blues...?</h1>
        {!gameOver ? (
          <>
            <h2>Guess the Theme</h2>
            <p className="score">Score: {score}/10</p>
            <img src={bluesImages[currentLevel].image} alt="Blues" className="blues-image" />
            <div className="options">
              {options[currentLevel].map((option, index) => (
                <button key={index} className="option-button" onClick={() => handleAnswer(option)}>
                  {option}
                </button>
              ))}
            </div>
          </>
        ) : (
          <>
            {perfectScore ? (
              <div className="perfect-score-message">
                <h2>Wow! You really do know Blues!</h2>
                <div className="confetti"></div>
                <button className="retry-button" onClick={retryGame}>Play Again</button>
              </div>
            ) : (
              <><h2>Game over! Your score is {score}/10</h2><button className="retry-button" onClick={retryGame}>Retry</button></>
            )}
          </>
        )}
        <div className="gallery-footer">
        <p>Check out more photos of different events on our Facebook and Instagram below!</p>
        <div className="arrow-down"></div>
      </div>
      </div>
    );
  };
  
  export default Gallery;