import React, { useEffect, useState } from 'react';
import '../App.css';
import MainContent from "./MainContent";
import ServicesSections from './ServicesSections';

const backgroundImages = [
  require('../assets/background.JPG'), require('../assets/background2.JPG'), require('../assets/background3.JPG'),
  require('../assets/background4.JPG'), require('../assets/background5.jpg'), require('../assets/background6.JPG'),
  require('../assets/background7.JPG'), require('../assets/background8.JPG'), require('../assets/background9.JPG'),
  require('../assets/background10.JPG'), require('../assets/background11.JPG')
];

function Home() {
  const [currentBackground, setCurrentBackground] = useState(0);

  const nextSlide = () => {
    setCurrentBackground((currentBackground + 1) % backgroundImages.length);
  };

  const prevSlide = () => {
    setCurrentBackground((currentBackground - 1 + backgroundImages.length) % backgroundImages.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  });

  return (
    <div>
      <div className="background-container">
        <div className='left-arrow' onClick={prevSlide}>&#10094;</div>
        <div className='right-arrow' onClick={nextSlide}>&#10095;</div>
        {backgroundImages.map((image, index) => (
          <div
            key={index}
            className={`fade-image ${currentBackground === index ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
        <div className="content-container">
          <MainContent />
        </div>
        <div className="indicator-container">
          {backgroundImages.map((_, index) => (
            <div
              key={index}
              className={`indicator ${currentBackground === index ? 'active' : ''}`}
              onClick={() => setCurrentBackground(index)}
            />
          ))}
        </div>
      </div>
      <ServicesSections />
    </div>
  );
}

export default Home;
