import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom"; 
import "../styles/main.css";
import logo from "../assets/logo.png";
import logowords from "../assets/logowords2.png";


function Navbar() {
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return (
        <header>
            <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
            </Link>
            <img src={logowords} alt="Logo" className="logowords" />
            <nav ref={navRef}>
                <Link to="/" onClick={showNavbar}>Home</Link>
                <Link to="/book-now" onClick={showNavbar}>Book Now</Link>
                <Link to="/meet-the-team" onClick={showNavbar}>Meet the Team</Link>
                <Link to="/gallery" onClick={showNavbar}>Gallery</Link>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <FaBars />
            </button>
        </header>
    );
}

export default Navbar;
