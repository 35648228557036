import React from 'react';
import '../styles/main.css';
import { Link } from 'react-router-dom'; 


function MainContent() {
  return (
    <div className="main-content">
      <h1>Need Photos?<br />We Got You Covered.</h1>
      <Link to="/book-now" className="book-now-btn">Book Now</Link>
    </div>
  );
}

export default MainContent;