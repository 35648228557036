import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import BookNow from './components/BookNow';
import MeetTheTeam from './components/MeetTheTeam';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import AOS from 'aos';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when refreshed
    
    AOS.init({
      duration: 500,
      once: true, // animation should happen only once
    });
  }, []);

  return (
    <Router>
      <div>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/book-now" element={<BookNow />} />
            <Route path="/meet-the-team" element={<MeetTheTeam />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </div>
        <Footer /> 
      </div>
    </Router>
  );
}

export default App;
