import React, { useState } from 'react';
import '../styles/bookNow.css'; 
import WeatherPopup from './WeatherPopup'; 

const BookNow = () => {

  const [showWeatherPopup, setShowWeatherPopup] = useState(false);

  return (
    <div className="book-now-container">
      <h1>Book Your Session</h1>
      <button className="outdoor-shoot-button" onClick={() => setShowWeatherPopup(true)}>Outdoor shoot?</button>
      {showWeatherPopup && <WeatherPopup onClose={() => setShowWeatherPopup(false)} />}
      <iframe 
        src="https://docs.google.com/forms/d/e/1FAIpQLSc2qbxnBgFQb2VAvqGHayuYzmrR-xcjlVRJcOdHdfKxl0O2jw/viewform?embedded=true" 
        width="640" 
        height="1782" 
        frameBorder="0" 
        marginHeight="0" 
        marginWidth="0"
        title="Booking Form"
      >
        Loading…
      </iframe>
    </div>
  );
}

export default BookNow;