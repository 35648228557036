import '../styles/meetTheTeam.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

// import all images from the assets directory
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
};

const images = importAll(require.context('../assets', false, /\.(png|jpe?g|JPG)$/));

const teamMembers = [
  { name: "Mariana Ninkovic", title: "Co-Prez & Photographer", image: images['Mariana.JPG'], instagram: '@mariana.nkvc', started: '2017' },
  { name: "Evan Rajkovic", title: "Co-Prez & Photographer", image: images['evan.jpg'], instagram: '@evanrjk', started: '2019' },
  { name: "Estelle Huy", title: "VP Finance & Photographer", image: images['Estelle.png'], instagram: '@estelle.huy', started: '2017' },
  { name: "Enzo Benoit-Jeannin", title: "VP Events & Photographer", image: images['enzo.jpg'], instagram: '@enzo_bj', started: '2014' },
  { name: "Emily Lee", title: "VP Admin", image: images['emily.jpg'], instagram: '@em.milee', started: '2020'  },
  { name: "Marcus Chong", title: "VP Communications & Photographer", image: images['Marcus.jpg'],  instagram: '@mxrcuschong', started: '2019' },
  { name: "Alois Luong", title: "Photographer", image: images['Alois L.jpg'],  instagram: '@alois_swimmer', started: '2018' },
  { name: "Kate Peng", title: "Photographer", image: images['Kate.JPG'],  instagram: '@pancake.kp', started: '2019' },
  { name: "Natasha Lawford", title: "Photographer & Software Developer", image: images['natasha.jpg'], instagram: '@natashalex_17', started: '2019'  },
  { name: "William Chamoun", title: "Photographer", image: images['Will.jpg'], instagram: '@william.chamoun', started: '2017'  },
  { name: "Winston Weng", title: "Photographer", image: images['winston.png'], instagram: '@winstonweng929', started: '2019'  },
  { name: "Ze Yuan Fu", title: "Photographer", image: images['Zeyuan.jpg'], instagram: '@zeyuanfu.fu', started: '2022'  }
];

function MeetTheTeam() {

  return (
    <div className="meet-the-team-container">
      <h1>Meet the Team</h1>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={member.image} alt={member.name} className="team-member-image" />
                </div>
                <div className="flip-card-back">
                <div className="instagram-handle">
                    <FontAwesomeIcon icon={faInstagram} className="instagram-icon" />
                    <p>: {member.instagram}</p>
                  </div>
                <p>Started Photography: {member.started}</p>
              </div>
            </div>
            </div>
            <h2>{member.name}</h2>
            <p2>{member.title}</p2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MeetTheTeam;
